import cn from 'classnames';
import React from 'react';

export enum ChipColor {
  NEUTRAL_50 = 'NEUTRAL_50',
  ELDERBERRY_50 = 'ELDERBERRY_50',
  JACKFRUIT_200 = 'JACKFRUIT_200',
  RADISH_50 = 'RADISH_50',
  SOURDOUGH = 'SOURDOUGH',
  WHITE = 'WHITE',
  RASPBERRY = 'RASPBERRY',
  CANTELOUPE_50 = 'CANTELOUPE_50',
  BUTTER_50 = 'BUTTER_50',
  MINT_50 = 'MINT_50',
}

const colorVariants = {
  [ChipColor.ELDERBERRY_50]: `bg-elderberry-50`,
  [ChipColor.JACKFRUIT_200]: `bg-jackfruit-200`,
  [ChipColor.RADISH_50]: `bg-radish-50`,
  [ChipColor.NEUTRAL_50]: `bg-neutral-50`,
  [ChipColor.SOURDOUGH]: `bg-sourdough`,
  [ChipColor.WHITE]: `bg-white`,
  [ChipColor.RASPBERRY]: `bg-raspberry text-white`,
  [ChipColor.CANTELOUPE_50]: `bg-canteloupe-50`,
  [ChipColor.BUTTER_50]: `bg-butter-50`,
  [ChipColor.MINT_50]: `bg-mint-50`,
};

const BASE_CSS = 'rounded flex gap-1 text-label-400 px-2 items-center text-eggplant w-fit';

interface ChipProps {
  color: ChipColor;
  copy: string;
  applyFullHeight?: boolean;
  icon?: React.ReactNode; // TODO: Restrict to Icon component
}

const Chip: React.FC<ChipProps> = ({ applyFullHeight = false, color, copy, icon }) => (
  <div className={cn(BASE_CSS, colorVariants[color], { 'h-full': applyFullHeight, 'h-6': !applyFullHeight })}>
    {icon && <span className='flex h-full w-5 items-center pb-0.5'>{icon}</span>}
    <span className='text-nowrap leading-6'>{copy}</span>
  </div>
);
export default Chip;
